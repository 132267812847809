html,
body {
	width: 100%;
	height: 100%;
	position: relative;
}

body {
	display: -ms-flexbox;
	display: flex;
	font-family: "cour-font";
	color: #ffffff;
}

.footer {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	/* background-color: #000000; */
	color: white;
}

#root {
	width: 100%;
}

/* gradient css starts */
body {
	background: linear-gradient(180deg, #283adc, #000000);
	background-size: 400% 400%;

	-webkit-animation: AnimationName 30s ease infinite;
	-moz-animation: AnimationName 30s ease infinite;
	animation: AnimationName 30s ease infinite;
}

@-webkit-keyframes AnimationName {
	0% {
		background-position: 50% 0%;
	}
	50% {
		background-position: 50% 100%;
	}
	100% {
		background-position: 50% 0%;
	}
}
@-moz-keyframes AnimationName {
	0% {
		background-position: 50% 0%;
	}
	50% {
		background-position: 50% 100%;
	}
	100% {
		background-position: 50% 0%;
	}
}
@keyframes AnimationName {
	0% {
		background-position: 50% 0%;
	}
	50% {
		background-position: 50% 100%;
	}
	100% {
		background-position: 50% 0%;
	}
}

/* gradient css over */

.container-fluid {
	padding: unset;
}

.nav-link img {
	height: 60px;
}

.nav-link.active {
	border-bottom: 5px solid #ffffff;
}

@font-face {
	font-family: "cour-font";
	src: url("./assets/fonts/nidsans-webfont.ttf");
}

.social-links a {
	color: #ffffff;
}

.btn_submit_default {
	border: 1px solid #ffffff;
	padding: 15px 25px;
	display: inline-block;
	/* text-transform: uppercase; */
	text-decoration: none;
	color: rgb(255, 255, 255);
	font-weight: 700;
	font-size: 15px;
	line-height: 15px;
	-webkit-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
	background-color: transparent;
	cursor: pointer;
}
.btn_submit_default i {
	padding-right: 5px;
	font-size: 12px;
}
.btn_submit_default:hover {
	background-color: #ffffff;
	border-color: #ffffff;
	color: #000000;
}

input[type="text"],
input[type="email"],
select.form-control {
	background: transparent;
	border: none;
	border-bottom: 1px solid #ffffff;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-radius: 0;
}

input[type="text"]:focus,
input[type="email"]:focus,
select.form-control:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	background: transparent;
	border-bottom: 1px solid #ffffff !important;
}

textarea {
	background: transparent !important;
	border: none !important;
	border-bottom: 1px solid #ffffff !important;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-radius: 0;
}

textarea:focus {
	-webkit-box-shadow: none;
	box-shadow: none !important;
	background: transparent;
	border-bottom: 1px solid #ffffff !important;
}

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #ffffff !important;
	opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #ffffff !important;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #ffffff !important;
}

.about-content div.custom-scroll::-webkit-scrollbar {
	width: 20px;
	left: 30px;
	background-color: #75d8ea;
	outline: 1px solid deeppink;
}

.about-content div.custom-scroll::-webkit-scrollbar-thumb {
	background: darkblue;
	border: 4px solid #75d8ea;
}

.about-content {
	border: 2px solid #75d8ea;
	border-radius: 30px;
	padding: 15px 10px 15px 5px;
	height: 600px;
	background-color: #3259d3;
}
.about-content div.custom-scroll {
	overflow-y: scroll;
	height: 560px;
}

.ReactGridGallery_tile {
	margin: 30px !important;
	border-radius: 15px;
}

.home-content {
	border: 2px solid #75d8ea;
	border-radius: 30px;
	/* padding: 15px 10px 15px 30px; */
	/* height: 500px; */
	background-color: #3259d3;
	overflow: hidden;
}

.slick-dots li.slick-active button:before {
	color: #fff !important;
}